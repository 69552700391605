@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primaryColor: #F7463C;
  --secColor1: #797979;
  --secColor2: #252525;
  --secColor3: #C83229;
  --secColor4: #FFFFFF;
  --secColor5: #FF4237;
  --appBg: #252525;
}

body {
  color: var(--secColor4) !important;
}

.fullPage {
  min-height: 80vh;
}

.App {
  background-color: var(--appBg);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 8%;
}

.App > *:not(.BottomBar) {
  flex: 1; /* Push the BottomBar to the bottom */
}

.BottomBar {
  position: relative;
}

.MuiAppBar-root {
  padding-top: 3%;
  background: linear-gradient(0deg, #252525 60%, #E33D34 88%, #F74036 97%, #FF4237 100%) !important;
  box-shadow: none !important;
}

.MuiIconButton-root {
  background: var(--primaryColor) !important;
  color: var(--appBg) !important;
}

.MuiIconButton-root:hover {
  background: var(--secColor4) !important;
}

.active {
  color: var(--primaryColor) !important;
}

a {
  color: var(--secColor4) !important;
  text-decoration: none !important;
}